<!--政策-->
<template>
  <div class="talent-page-content">
    <t-nav
      :list="navData.navList"
      :name="navData.title"
      :defaultNav="current"
      @changeNav="changeNav"
    />
    <div class="talent-data-content" v-show="!detailShow">
      <div class="talent-data-title">{{ activeName }}</div>
      <div class="talent-data-search">
        <a-input-search
          class="talent-nav-search-input"
          placeholder="请输入要搜索的内容"
          enter-button="搜索"
          size="large"
          @search="onSearch"
        />
      </div>
      <div class="img-noData" v-if="list.length===0"><img src="@/assets/image/noData.png" alt="" /></div>
      <div class="talent-data-list" v-if="list.length>0">
        <div class="gov-span" v-for="item in list" :key="item.id" @click="showDetail(item.id)">
        <div class="gov-item" >
          <div class="gov-title">
            <span class="gov-spam-rz "> ●{{item.title}}</span>
          </div>
          <div class="gov-date">
            {{item.publishTime}}
          </div>
        </div>
        </div>
        <a-pagination :show-total="total => `共 ${total} 条`"  show-quick-jumper v-if="total>10" v-model="pageNum" :total="total" @change="change" show-less-items />
      </div>
    </div>
    <div class="gov-detail" v-show="detailShow">
<!--          <a-page-header
            title="返回"
            @back="detailShow=false"
        />-->
      <div class="gov-detail-top">
        <div class="gov-detail-top-title">
          {{ selDetail.title }}
        </div>
        <div class="gov-detail-top-date">发布日期：{{ selDetail.publishTime }}</div>
      </div>
      <div class="gov-detail-content" v-html="selDetail.context"></div>
    </div>
  </div>
</template>
<script>
import {getPoliticsList,selPolitics} from "../../api/company/politics";
import {getDictType} from "../../api/company/dictType";
export default {
  data() {
    return {
      form:{
        dictId:null,
        title:'',
      },
      pageNum:1,
      pageSize:10,
      total:10,
      navData: {
        navList: [
          { name: "法律法规规章", id: 0 },
          { name: "国家政策", id: 1 },
          { name: "省级政策", id: 2 },
          { name: "市级政策", id: 3 },
          { name: "区级政策", id: 4 },
          { name: "平台政策", id: 5 },
        ],
        title: "创新政策",
        default: 0,
      },
      activeName: "法律法规规章",
      detailShow: false,
      current: null,
      selDetail: {
        title:
          "湖南省实施《中华人民共和国促进科技成果转化法》办法（2019年修订）",
        publishTime: "2011/11/11",
        content:
          "产养殖数据库，立足于无人船平台，瞄准服务水产养殖全产业链，用大数据的思维，指导水产企业养殖品类与规模、指导水产养殖原料供应商生产计划、指导水产产品下游企业生产计划、支撑国家水产养殖行业精准扶持、支撑水产养殖市场政策调控，实现了公司从产品经济至数据经济",
      },
      list:[],
    };
  },
  methods: {
    change(){
      getPoliticsList({pageNum:this.pageNum,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.list;
        this.total=res.data.total;
      });
    },
  async  changeNav(item) {
        this.detailShow = false;
        //console.log(this.detailShow)
        //todo 切换分类 重新加载数据
        this.activeName = item.name;
        //console.log(item);
        this.form.dictId=item.id;
      await  getPoliticsList({pageNum:1,pageSize:this.pageSize,form:this.form}).then(res=>{
          //console.log(res);
          this.list=res.data.list;
          this.total=res.data.total;
        });
    },
    onSearch(val) {
      this.form.title=val;
      getPoliticsList({pageNum:1,pageSize:this.pageSize,form:this.form}).then(res=>{
        //console.log(res);
        this.list=res.data.list;
        this.total=res.data.total;
      });
    },
    showDetail(id) {
      //console.log(id);
      this.detailShow = true;
      selPolitics({id:id}).then(res=>{
          //console.log(res);
          this.selDetail=res.data;
        //console.log(this.selDetail,"this.selDetail");
      })
    },
   async init(){
     await getDictType(48).then(res=>{
       this.navData.navList=res.data
     })
      let key=this.$route.query.key===undefined?'':this.$route.query.key;
      this.form.title=key;
      let data={id:null,name:""}
      if(this.$route.query.key===undefined){
        data=this.navData.navList[0];
        this.current=this.navData.navList[0].id;
      }
      await  this.changeNav(data)
      this.form.title='';
    }
  },
  mounted() {
    this.init()
  }
};
</script>
<style lang="scss" scoped>
.talent-data-search {
  margin: 25px 0;
  .talent-nav-search-input {
    width: 419px;
    .ant-btn {
      background: #ff9a02;
      border-color: #ff9a02;
      border-radius: 0;
    }
  }
}
.talent-data-list {
  .gov-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    padding: 10px 0;
    border-bottom: 2px solid #efefef;
    cursor: pointer;
    .gov-title {
      width: 800px;
      color: #333333;
    }
    .gov-date {
      color: #999999;
    }
  }
  .gov-span :hover{
      .gov-spam-rz {
        color : #044fc6;
      }
  }
}
.gov-detail {
  width: 900px;
  background: #f8fbff;
  padding: 40px 30px;
  color: #666666;
  position: relative;
  .gov-detail-top {
    text-align: center;
    line-height: 36px;
    border-bottom: 2px solid #efefef;
    padding-bottom: 20px;
    .gov-detail-top-title {
      color: #333333;
      font-weight: 600;
      font-size: 22px;
    }
    .gov-detail-top-date {
      font-size: 16px;
    }
  }
  .gov-detail-content {
    margin-top: 40px;
    line-height: 20px;
  }
}
</style>
