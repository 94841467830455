import request from '@/utils/request'

export function getPoliticsList(data) {
    return request({
        url: '/com/politics/list/'+data.pageNum+'/'+data.pageSize,
        method: 'post',
        params: data.form
    })
}

export function selPolitics(data) {
    return request({
        url: '/com/politics/sel',
        method: 'get',
        params: data
    })
}